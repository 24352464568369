
<script setup lang="ts">
    import { computed } from "vue";

    export interface Props {
        text?: string;
        icon?: string;
        spinner?: boolean;
        color: "primary";
        variant?: "contained" | "outlined";
    };

    const props = withDefaults(defineProps<Props>(), { variant: "contained" });

    const className = computed(() => {
        if (props.color === "primary") {
            if (props.variant === "contained") {
                return "btn btn-primary";
            } else if (props.variant === "outlined") {
                return "btn btn-outline-primary";
            }
        }
    });
</script>

<template>
    <button class="c-button" :class="className" v-bind="$attrs">
        <div class="d-flex align-items-center gap-1">
            <template v-if="spinner">
                <div class="spinner-border spinner-border-sm" />
            </template>
            <template v-if="icon">
                <i class="bi" :class="icon" />
            </template>
            <template v-if="text">
                <span>{{ text }}</span>
            </template>
        </div>
    </button>
</template>

<style scoped>
    .c-button {
        /* border: none; */
    }
</style>
